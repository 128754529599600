<template>
    <div class="AddAccount">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="编辑账户" :visible.sync="zhanghu" @close="closeDialog()" width="600px" :closeOnClickModal='false' prop-class="dialog">
                <!-- <div class="chuangjian">新增账户</div> -->
                <el-form :model="form">
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            账户ID
                        </div>
                        <el-input v-model="form.agent_account_params[0].agent_account" type="text" oninput="value=value.replace(/[^\d\.]/g,'')" autocomplete="off"  placeholder="请输入账户ID"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            账户ID名称
                        </div>
                        <el-input v-model="form.agent_account_params[0].agent_account_name" autocomplete="off" placeholder="请输入账户ID名称"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            公户存档
                        </div>
                        <el-input v-model="form.family_archive" autocomplete="off" placeholder="请输入公户存档"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            指定端口id
                        </div>
                        <el-select v-model="form.account_port_id" placeholder="请选择端口" popper-class='flock-select'>
                            <div v-infinite-scroll="load" style="" class="flock-load">
                                <el-option v-for='item in inputD' :key="item.id" :label="item.advertiser_name" :value="item.id"></el-option>
                            </div>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            运营类型
                        </div>
                        <el-radio-group v-model="form.is_operating_type">
                            <el-radio label="1">自运营</el-radio>
                            <el-radio label="2">代运营</el-radio>
                            <el-radio label="3">渠道</el-radio>
                            <el-radio label="4">W渠道</el-radio>
                            <el-radio label="5">cid</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="quxiao" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">提 交</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    props: ["msg", "id"],
    data() {
        return {
            zhanghu: true, //操控弹窗展示
            form: {
                
                family_archive: "", //公户存档
                account_port_id: "", //指定端口id
                is_operating_type: "", //运营类型
                agent_account_id: "", //账户id
                agent_account_params:[
                    {
                        agent_account: "", //账户ID
                        agent_account_name: "", //账户ID姓名
                    }
                ]
            },
            formLabelWidth: "140px",
            activeName: "", //显示组件
            page: 1,
            inputD: [],
            jieshou: "",
        };
    },
    mounted() {
        this.getAdvertiserList();
        if (this.id != "") {
            var param = {
                agent_account_id: this.id,
            };
            this.$service.get(this.$api.getTopAgentAccountRes, param, (res) => {
                if (res.code == "200") {
                    this.form.agent_account_params[0].agent_account = res.data.agent_account;
                    this.form.agent_account_params[0].agent_account_name = res.data.agent_account_name;
                    this.form.family_archive = res.data.family_archive;
                    this.form.account_port_id = res.data.account_port_id;
                    this.form.is_operating_type = res.data.is_operating_type;
                    this.form.agent_account_id = res.data.id;
                }
            });
        }
        if (this.msg == AddAccount) {
            this.activeName == true;
        }
    },
    methods: {
        //获取账户
        getAdvertiserList() {
            var param = {
                page: this.page,
            };
            this.$service.get(this.$api.getAdvertiserList, param, (res) => {
                if (res.code == "200") {
                    this.inputD = res.data.data;
                    // console.log(this.inputD);
                }
            });
        },
        //选择运营官触底事件
        load() {
            // console.log(1111);
            this.page += 1;
            var param = {
                page: this.page,
            };
            this.$service.get(this.$api.getAdvertiserList, param, (res) => {
                var data = res.data.data;
                if (data == "") {
                    this.$message({
                        message: "已没有更多数据",
                        type: "warning",
                    });
                    return;
                }
                if (res.code == "200") {
                    var inputD = this.inputD;
                    data.forEach(function (item, index) {
                        inputD.push(item);
                    });
                    this.inputD = inputD;
                }
            });
        },
        //
        quxiao() {
            this.$emit("zhanghu");
            this.zhanghu = false;
        },
        //
        closeDialog() {
            this.$emit("bianji");
            this.zhanghu = false;
        },
        tijiao() {
            var data = this.form;
            var that = this;
            // console.log(data);
            this.$service.post(this.$api.createTopAgentAccount, data, (res) => {
                if (res.code == "200") {
                    this.$message({
                        message: "提交成功",
                        type: "success",
                    });
                    that.$emit("bianji");
                    that.zhanghu = false;
                }
            });
            // if (data.agent_account == "") {
            //     this.$message({ message: "请输入账户ID", type: "warning" });
            //     return;
            // }
            // if (data.agent_account_name == "") {
            //     this.$message({
            //         message: "请输入账户ID名称",
            //         type: "warning",
            //     });
            //     return;
            // }
            // if (data.family_archive == "") {
            //     this.$message({ message: "公户存档", type: "warning" });
            //     return;
            // }
            // if (data.account_port_id == "") {
            //     this.$message({ message: "请选择端口ID", type: "warning" });
            //     return;
            // }
            // if (data.is_operating_type == "") {
            //     this.$message({ message: "请选择运营类型", type: "warning" });
            //     return;
            // }
        },
    },
    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.AddAccount {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    //
}
</style>
<style lang="scss">
.AddAccount {
    .el-form-item__content{
        display: flex;
        .el-input{
            width: 400px;
        }
    }
    .upload-demo {
        width: 200px;
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog {
        width: 600px;
        top: 30px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
}
</style>