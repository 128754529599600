<template>
    <div class="operation">
        <!--  -->
        <div class="head padd20 borbut20">
            <div class="topSearchSou">
                <div class="searchdan">
                    <div class="title">账户名称：</div>
                    <el-input v-model="input" placeholder="请输入账户名称或ID"></el-input>
                </div>
                <div class="searchdan">
                    <div class="title">群名称：</div>
                    <el-select v-model="company_id" filterable placeholder="请输入群名称" remote clearable @change="currentSel" reserve-keyword :remote-method="Tolikesearch">
                        <!-- <div v-infinite-scroll="qunload" style="" class="flock-load"> -->
                        <el-option v-for="item in qunlist" :key="item.value" :label="item.company_name" :value="item.id"></el-option>
                        <!-- </div> -->
                    </el-select>
                </div>
                <div class="searchdan">
                    <div class="title">企业名称：</div>
                    <el-select v-model="corporate_account_id" placeholder="请输入企业名称" filterable remote clearable @change="currentSel" reserve-keyword :remote-method="Tolikeqy">
                        <!-- <div v-infinite-scroll="qiyeload" style="" class="flock-load"> -->
                        <el-option v-for="item in qiyelist" :key="item.id" :label="item.corporate_account_name" :value="item.id"></el-option>
                        <!-- </div> -->
                    </el-select>
                </div>
                <div class="searchdan">
                    <div class="title">端口：</div>
                    <el-select v-model="account_port_id" placeholder="请输入端口" filterable remote clearable @change="currentSel" reserve-keyword :remote-method="Tolikedk">
                        <!-- <div v-infinite-scroll="dkload" style="" class="flock-load"> -->
                        <el-option v-for="item in accountlist" :key="item.value" :label="item.advertiser_name" :value="item.id"></el-option>
                        <!-- </div> -->
                    </el-select>
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onButtonInquire()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
            </div>
            <!-- <div class="zhong">
                
                
                
                <el-button type="primary" icon="el-icon-search" @click="sousuo()">搜索</el-button>
            </div> -->
        </div>
        <!--  -->
        <div class="tab padd20">
            <div class="duoshao">
                <div class="title">账户列表</div>
                <el-button type="primary" id="AddAccount" @click="addzhanghu($event)" class="yinyinga" icon="el-icon-plus">新增账户</el-button>
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table ref="filterTable" :data="tableData" style="width: 100%">
                <el-table-column prop="id" label="ID">
                </el-table-column>
                <el-table-column prop="agent_account" label="账户ID">
                </el-table-column>
                <el-table-column prop="agent_account_name" label="账户ID名称">
                </el-table-column>
                <el-table-column prop="is_operating_type" label="运营类型">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_operating_type == 1"> 自运营</span>
                        <span v-if="scope.row.is_operating_type == 2">代运营</span>
                        <span v-if="scope.row.is_operating_type == 3">渠道</span>
                        <span v-if="scope.row.is_operating_type == 4">W渠道</span>
                        <span v-if="scope.row.is_operating_type == 5">cid</span>
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="创建时间">
                </el-table-column>
                <el-table-column prop="updated_at" label="更新时间">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" class="bianji" size="mini" plain @click="handleEdit(scope.$index, scope.row ,$event) " id="ComAccount">编辑</el-button>
                        <!-- <el-popconfirm title="确定要删除本条记录吗" @confirm="onEnter(scope.row)">
                            <el-button size="mini" slot="reference" type="danger">删除</el-button>
                        </el-popconfirm> -->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="fenye">
                <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
            </div>
        </div>

        <!-- 弹窗 -->
        <AddAccount ref="AddAccount" :msg="activeName" @zhanghu="zhanghu" @bianji="bianji"></AddAccount>
        <!-- <AddAccount ref="AddAccount" :msg="activeName" @zhanghu="zhanghu" @bianji="bianji" v-if="activeName == 'AddAccount'"></AddAccount> -->
        <ComAccount ref="ComAccount" :id="operating_id" @zhanghu="zhanghu" :msg="activeName" @bianji="bianji" v-if="activeName == 'ComAccount'"></ComAccount>
    </div>
</template>
 
<script>
import AddAccount from "./AddAccount.vue";
import ComAccount from "./ComAccount.vue";
export default {
    data() {
        return {
            paramData: {}, //表格参数
            input: "", //搜索内容
            operating_id: "", //运营官id
            tableData: [], //显示运营列表
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1, //表格当前第几页
            page: "1",
            activeName: "",
            PageTopCompanyList: 1, //群页码
            // qunid: "", //群id
            company_id: "", //群id
            qunlist: "", //群循环
            PageTopCorporateAccountList: 1, //企业页码
            corporate_account_id: "", //企业id
            qiyelist: "", //企业循环
            account_port_id: "", //端口id
            accountlist: "", //端口列表
            PagegetAdvertiserList: "", //端口页码
        };
    },
    mounted() {
        this.ongetTopAgentAccountList();
        this.getTopCompanyList(); //获取群列表
        this.animateWidth(); //获取企业
        this.getAdvertiserList(); //获取端口
    },
    components: {
        AddAccount,
        ComAccount,
    },
    methods: {
        //搜索
        onButtonInquire() {
            this.paramData = {
                agent_account: this.input,
                account_port_id: this.account_port_id,
                company_id: this.company_id,
                corporate_account_id: this.corporate_account_id,
                company_name: this.company_name,
            };
            this.paramPage = 1;
            this.ongetTopAgentAccountList();
        },
        //群列表
        ongetTopAgentAccountList() {
            this.tableData = [];
            var param = this.paramData;
            param.page = this.paramPage;
            this.$service.get(
                this.$api.getTopAgentAccountList,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.tableData = res.data.data;
                        // console.log(this.tableData);
                        this.lvtotal = res.data.total;
                    }
                }
            );
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val;
            this.ongetTopAgentAccountList();
        },
        //账户列表
        sousuo() {},
        //获取端口列表
        getAdvertiserList() {
            var param = {
                page: 1,
            };
            this.$service.get(this.$api.getAdvertiserList, param, (res) => {
                if (res.code == "200") {
                    this.accountlist = res.data.data;
                    // console.log(this.accountlist);
                }
            });
        },
        // 端口选择事件
        Tolikedk(query) {
            this.account_port_id = query;
            var param = {
                page: 1,
                advertiser_name: query,
            };
            this.$service.get(this.$api.getAdvertiserList, param, (res) => {
                if (res.code == "200") {
                    this.accountlist = res.data.data;
                }
            });
        },
        //端口触底事件
        dkload() {
            if (this.account_port_id) {
                return;
            }
            this.PagegetAdvertiserList++;
            var param = {
                page: this.PagegetAdvertiserList,
            };
            this.$service.get(this.$api.getAdvertiserList, param, (res) => {
                var data = res.data.data;
                if (res.code == "200") {
                    if (res.data.per_page == 10 || res.data.per_page > 0) {
                        var bankcard = this.accountlist;
                        data.forEach(function (item, index) {
                            bankcard.push(item);
                        });
                        this.accountlist = bankcard;
                    } else {
                        this.PagegetAdvertiserList--;
                    }
                }
            });
        },

        //获取企业列表
        animateWidth() {
            var param = {
                page: 1,
            };
            this.$service.get(
                this.$api.getTopCorporateAccountList,
                param,
                (res) => {
                    if (res.code == "200") {
                        // console.log(res.data);
                        this.qiyelist = res.data.data;
                    }
                }
            );
        },
        // 企业选择事件
        Tolikeqy(query) {
            this.corporate_account_id = query;
            var param = {
                page: 1,
                corporate_account_name: query,
            };
            this.$service.get(
                this.$api.getTopCorporateAccountList,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.qiyelist = res.data.data;
                    }
                }
            );
        },
        //企业触底事件
        qiyeload() {
            var that = this;
            if (that.corporate_account_id) {
                return;
            }
            that.PageTopCorporateAccountList++;
            var param = {
                page: that.PageTopCorporateAccountList,
            };
            that.$service.get(
                that.$api.getTopCorporateAccountList,
                param,
                (res) => {
                    var data = res.data.data;
                    if (res.code == "200") {
                        var qiyelist = this.qiyelist;
                        // console.log(qiyelist);
                        if (res.data.per_page == 10 || res.data.per_page > 0) {
                            var qiyelist = this.qiyelist;
                            data.forEach(function (item, index) {
                                qiyelist.push(item);
                            });
                            this.qiyelist = qiyelist;
                        } else {
                            this.PageTopCorporateAccountList--;
                        }
                    }
                }
            );
        },
        //获取群列表
        getTopCompanyList() {
            var param = {
                page: 1,
            };
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                if (res.code == "200") {
                    this.qunlist = res.data.data;
                    // console.log(this.qunlist);
                }
            });
        },
        // 群搜索
        currentSel(selVal) {
            // console.log(selVal);
        },
        //搜索群发起请求 传入值为当前select输入的值
        Tolikesearch(query) {
            this.company_id = query;
            var param = {
                page: 1,
                company_name: query,
            };
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                if (res.code == "200") {
                    this.qunlist = res.data.data;
                }
            });
        },
        //群触底事件
        qunload() {
            if (this.company_id) {
                return;
            }
            this.PageTopCompanyList++;
            var param = {
                page: this.PageTopCompanyList,
            };
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                var data = res.data.data;
                if (res.code == "200") {
                    if (res.data.per_page == 10 || res.data.per_page > 0) {
                        var qunlist = this.qunlist;
                        data.forEach(function (item, index) {
                            qunlist.push(item);
                        });
                        this.qunlist = qunlist;
                    } else {
                        this.PageTopCompanyList--;
                    }
                }
            });
        },
        //新增账户
        addzhanghu(e, index) {
            this.activeName = e.currentTarget.id;
            this.$refs.AddAccount.onzhanghu()
            // console.log(this.activeName,123123)
        },
        //   创建账户
        zhanghu(data) {
            this.activeName = "";
        },

        // 筛选
        filterTag(value, row) {
            return row.tag === value;
        },
        // 编辑
        handleEdit(index, row, e) {
            this.activeName = e.currentTarget.id;
            this.operating_id = row.id;
        },
        //编辑回传
        bianji() {
            // console.log(111);
            this.ongetTopAgentAccountList();
            this.activeName = "";
        },
        // 删除
        // handleDelete(index, row) {
        //     console.log(index, row);
        // },
    },
};
</script>

<style lang="scss" scoped>
.operation {
    display: flex;
    flex-direction: column; //垂直显示
    .head {
        display: flex;
        justify-content: center;
    }

    .tab {
        img {
            width: 50px;
            height: 50px;
        }
    }
    .fenye {
        text-align: right;
        margin-top: 25px;
        margin-right: 50px;
    }
    .xzyunying {
        margin-bottom: 20px;
    }
    .ii {
        width: 20px;
        height: 20px;
    }
    .iconfont {
        // color: red;
        // margin-top: 24px;
    }
    .red {
        color: red;
    }
}
</style>
<style lang="scss">
.operation {
    // .el-input {
    //     width: 180px;
    //     margin-right: 15px;
    // }
    .el-step__title {
        margin-left: -5px;
    }

    .el-table__column-filter-trigger i {
        color: #0075f7;
        margin-left: 10px;
    }
    .el-form-item__label {
        text-align: left;
    }
    .el-form-item {
        margin-top: 20px;
    }
    .el-form-item__content {
        margin-left: 0 !important;
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
    .el-dialog__header {
        padding: 20px 20px;
    }
}
</style>